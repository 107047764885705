import React from 'react';
import '../../styles/Insta.css';

function index() {

  <script
    src="https://cdn2.woxo.tech/a.js#65962b1c435a9a2183aa0dfb"
    async data-usrc>
  </script>

  return (
    <>
    <div
      loading="lazy"
      data-mc-src="951c1dcb-0f5a-4a4b-a1b1-5e92518c9fe6#instagram"></div>
    <br /> <br/> <br/>
    </>
  )
}

export default index