import React from 'react';
import FacilitiesHero from '../../components/Facilities/FacilitiesHero';
import FacilitiesGallery from '../../components/Facilities/FacilitiesGallery';

function index() {
  return (
    <>
        <FacilitiesHero />
        <FacilitiesGallery />
    </>
  )
}

export default index