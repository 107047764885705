import React from 'react';
import EventsPage from '../../components/Events/index'

function index() {
  return (
    <>
        <EventsPage />
    </>
  )
}

export default index