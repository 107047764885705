const mainCarouselData = [
    {
        carouselImg: '/vscimages/carouselmain/carouselA.jpg'
    },

    {
        carouselImg: '/vscimages/carouselmain/carouselB.jpg'
    },

    {
        carouselImg: '/vscimages/carouselmain/carouselC.jpg'
    },

    {
        carouselImg: '/vscimages/carouselmain/carouselD.jpg'
    },

    {
        carouselImg: '/vscimages/carouselmain/carouselF.jpg'
    },
];

export default mainCarouselData;