const faciltiesData = [
    // {
    //     faciImage: '/vscimages/facilities/Billiards.jpg',
    //     faciLabel: "BILLIARDS"
    // },

    {
        faciImage: '/vscimages/facilities/Gym.jpg',
        faciLabel: "GYM"
    },

    {
        faciImage: '/vscimages/facilities/Basketball Court.jpg',
        faciLabel: "BASKETBALL COURT"
    },

    {
        faciImage: '/vscimages/facilities/Boxing Gym.jpg',
        faciLabel: "BOXING GYM"
    },

    {
        faciImage: '/vscimages/facilities/Jacuzzi.jpg',
        faciLabel: "JACUZZI"
    },

    {
        faciImage: '/vscimages/facilities/Threadmill Area.jpg',
        faciLabel: "TREADMILL AREA"
    },

    {
        faciImage: '/vscimages/facilities/Table Tennis Court.jpg',
        faciLabel: "TABLE TENNIS COURT"
    }
];

export default faciltiesData;