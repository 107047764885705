const activitiesData = [
    {
        actImg: '/vscimages/icons/gym.png',
        actDesc: "Fitness Gym & Private Gyms"
    },

    {
        actImg: '/vscimages/icons/pool.png',
        actDesc: "50-meter Swimming Pool"
    },

    // {
    //     actImg: '/vscimages/icons/sports bar.png',
    //     actDesc: "Bowling Alley"
    // },

    {
        actImg: '/vscimages/icons/sports facilities.png',
        actDesc: "Complete Range of Indoor Sports Club"
    },

    {
        actImg: '/vscimages/icons/theatre.png',
        actDesc: "Private Movie Lounge for Theater"
    },

    // {
    //     actImg: '/vscimages/icons/wall climbing.png',
    //     actDesc: "Wall Climbing"
    // },

    {
        actImg: '/vscimages/icons/gym.png',
        actDesc: "Fitness Gym & Private Gyms"
    },

    {
        actImg: '/vscimages/icons/pool.png',
        actDesc: "50-meter Swimming Pool"
    },

    // {
    //     actImg: '/vscimages/icons/sports bar.png',
    //     actDesc: "Bowling Alley"
    // },

    {
        actImg: '/vscimages/icons/sports facilities.png',
        actDesc: "Complete Range of Indoor Sports Club"
    },

    {
        actImg: '/vscimages/icons/theatre.png',
        actDesc: "Private Movie Lounge for Theater"
    },

    // {
    //     actImg: '/vscimages/icons/wall climbing.png',
    //     actDesc: "Wall Climbing"
    // },

    {
        actImg: '/vscimages/icons/gym.png',
        actDesc: "Fitness Gym & Private Gyms"
    },

    {
        actImg: '/vscimages/icons/pool.png',
        actDesc: "50-meter Swimming Pool"
    },

    // {
    //     actImg: '/vscimages/icons/sports bar.png',
    //     actDesc: "Bowling Alley"
    // },

    {
        actImg: '/vscimages/icons/sports facilities.png',
        actDesc: "Complete Range of Indoor Sports Club"
    },

    {
        actImg: '/vscimages/icons/theatre.png',
        actDesc: "Private Movie Lounge for Theater"
    },

    // {
    //     actImg: '/vscimages/icons/wall climbing.png',
    //     actDesc: "Wall Climbing"
    // },
];

export default activitiesData;