import React from 'react'
import Inquiry from '../../components/Inquiry'

function index() {
  return (
    <>
        <Inquiry />
    </>
  )
}

export default index